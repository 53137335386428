@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

body {
    font-family: 'Rubik', sans-serif;
}

h1 {
    font-size: 32px;
    font-weight: 500;
    color: #002d56
}

h2 {
    font-size: 26px;
    font-weight: 500;
    color: #0872ba
}

h3 {
    font-size: 24px;
    font-weight: 400;
    color: #002d56
}

h4 {
    font-size: 22px;
    font-weight: 300;
    color: #0872ba
}

h5 {
    font-size: 20px;
    font-weight: 300;
    color: #002d56
}

h6 {
    font-size: 18px;
    font-weight: 300;
    color: #0872ba
}

p {
    font-size: 16px;
    color: #002d56
}

ul {
    font-size: 15px;
    color: #002d56
}

a {
    color: #002d56
}
a:hover {
    text-decoration: none;
    color: #0872ba
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.contentContainer {
    padding-top: 30px;
    padding-bottom: 30px;
}