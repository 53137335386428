@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
a {
    color: white
}

/* Mobile/Small Tablet Screen view */
@media screen and (max-width: 999px) {
    .MainHeader_HeaderBG__3pPtE {
        background-color: #002d56;
        margin-bottom: 70px;
    }
    .MainHeader_Container__2N1mF {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .MainHeader_Logo__3ApIX {
        width: 100%;
        height: auto;
    }

    .MainHeader_navbar-dark__3UGss .MainHeader_navbar-toggler__3iwQ- {
        float: right
    }
    .MainHeader_BlueBar__2rUTE {
        display: none;
        height: 300px;
        width: 100%;
        background-image: url(/static/media/coldroom.713a7ab8.jpg);
        background-size: 100%;
        z-index: -100;
        position: absolute;
    }
}

/* Standard Screen & large tablet widescreen view */
@media screen and (min-width: 1000px) {
    .MainHeader_HeaderBG__3pPtE {
        background-image: url(/static/media/bg4.781f33a7.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 300px;
        margin-bottom: 250px;
    }
    .MainHeader_Container__2N1mF {
        padding-top: 45px;
    }
    .MainHeader_Logo__3ApIX {
        width: 400px;
        height: auto;
    }
    .MainHeader_BlueBar__2rUTE {
        height: 500px;
        width: 100%;
        background-image: url(/static/media/coldroom.713a7ab8.jpg);
        background-size: 100%;
        z-index: -100;
        position: absolute;
    }
}



.MainFooter_Footer__3VEbu p {
    font-size: 12px;
}
.MainFooter_Logo__1sbup {
    width: 100%;
}
.CallToAction1_CTABody__G9rNG {
    width: 100%;
    background-image: url(/static/media/cta-bg.d251bdc7.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.CallToAction1_CTAContainer__2TqAY {
    padding: 20px 0;
}

.CallToAction1_CTAContainer__2TqAY h4 {
    font-size: 25px;
    color: white;
}
.CallToAction1_CTAContainer__2TqAY h6 a {
    color: white;
    text-decoration: none;
    font-weight: 400;
    text-align: center;
}
/* todo - fix the height for smaller screens */
.CallToAction1_CTAContainer__2TqAY p {
    color: white;
    font-size: 14px;
    height: 10px
}
.CallToAction1_CTAContainer__2TqAY a {
    color: white;
    font-size: 15px;
}
body {
    font-family: 'Rubik', sans-serif;
}

h1 {
    font-size: 32px;
    font-weight: 500;
    color: #002d56
}

h2 {
    font-size: 26px;
    font-weight: 500;
    color: #0872ba
}

h3 {
    font-size: 24px;
    font-weight: 400;
    color: #002d56
}

h4 {
    font-size: 22px;
    font-weight: 300;
    color: #0872ba
}

h5 {
    font-size: 20px;
    font-weight: 300;
    color: #002d56
}

h6 {
    font-size: 18px;
    font-weight: 300;
    color: #0872ba
}

p {
    font-size: 16px;
    color: #002d56
}

ul {
    font-size: 15px;
    color: #002d56
}

a {
    color: #002d56
}
a:hover {
    text-decoration: none;
    color: #0872ba
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.contentContainer {
    padding-top: 30px;
    padding-bottom: 30px;
}
