a {
    color: white
}

/* Mobile/Small Tablet Screen view */
@media screen and (max-width: 999px) {
    .HeaderBG {
        background-color: #002d56;
        margin-bottom: 70px;
    }
    .Container {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Logo {
        width: 100%;
        height: auto;
    }

    .navbar-dark .navbar-toggler {
        float: right
    }
    .BlueBar {
        display: none;
        height: 300px;
        width: 100%;
        background-image: url("/src/images/header/coldroom.jpg");
        background-size: 100%;
        z-index: -100;
        position: absolute;
    }
}

/* Standard Screen & large tablet widescreen view */
@media screen and (min-width: 1000px) {
    .HeaderBG {
        background-image: url("/src/images/header/bg4.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 300px;
        margin-bottom: 250px;
    }
    .Container {
        padding-top: 45px;
    }
    .Logo {
        width: 400px;
        height: auto;
    }
    .BlueBar {
        height: 500px;
        width: 100%;
        background-image: url("/src/images/header/coldroom.jpg");
        background-size: 100%;
        z-index: -100;
        position: absolute;
    }
}


